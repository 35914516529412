/* default modal styles */
.modal{ pointer-events: none; }
.modal-dialog{ pointer-events: none; }
.modal-content{ pointer-events: auto; } 

/* create/edit appraisal modal */
.appraisalModalStyle { width:800px; max-width: none; }

/* reports */
.reportModalStyle { width:900px; max-width: none;}
.findingsReportModalStyle { width:1100px; max-width: none;}