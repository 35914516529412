.treeControlContainer {
    background: white;
    border: none;
    border-radius: 0.5%;
    padding: 1%;    
}

.treeContainer{
    height: 70vh;
    width: 480px;
    border: none;
}

.ReactVirtualized__Grid {
    outline: none;
}

 .ReactVirtualized__Grid__innerScrollContainer {
    padding-bottom: 70vh;
} 

.fa{
    margin-right: 10px;
    color: rgb(0,168,168);    
}

.node{
    display: inline-flex;
    align-items: center;
    width: 430px;
    font-size: 16px;    
    padding: 0px !important;
}

.image{
    height: 15px;
    width: 15px;
}

.nodeLabel {
    margin-left: 10px;
    font-weight: normal;
    color: dimgray;
}

.select {
    width: 35px;
    align-content: center;
}

a:visited {
    color: #0000EE;
}