.up-down-buttons {
    display: flex;
    flex-direction: row;
}

.up-down-button {
    max-width: 40px;
    margin-right: 2px;
}

.add-delete-button {
    float: right;
}

.toolbar-button {
    margin-left: 5px;
}