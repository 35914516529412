.projLabelName {
    background-color: #FFF;
    border: #FFF;
    height: 14px;
    width: 90%;
    text-align: left;
    color: #000000;
    font-size: inherit;
    border-radius: 0;
}

.delProjButton {
    background-color: #FFF;
    height: 25px;
    width: 10%;
    box-sizing: border-box;
    border: #FFF;
    border-radius: 0;
}

.delProjButton:hover{
    background: #e1e1e1;
    border-top: 1px solid #d0d0d0;
}

.projectItems{
    display: flex;
    align-items: baseline;
}

.list-group-item {
    padding: 0px;
}
