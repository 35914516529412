.checkBoxes {
    float: right;
}

.addTextBox {
    display: inline-block;
    width: 85%;
}

.addButton {
    display: inline-block;
    width: 10%;
    margin-left: 15px;
    margin-bottom: 5px;
    height: 34px;    
}

.projectsListGroup{
    overflow-y: scroll;
    font-size: 17px;
    max-height: 250px;
    max-width: 87%;
    color: dimgray;
}

.listControls{
    display: grid;
    grid-template-columns: 1fr 80px 1fr;
    grid-template-rows: 200px 200px;
    grid-column-gap: 5px;
}

.controlLabelOne{
    display: inline-block;
    color: dimgray;
}

.controlLabelTwo{
    float: right;
    color: dimgray;
}

.projectPAListGroup{
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 3;
    overflow-y: scroll;
    max-width: 100%;
    font-size: 14px;
    color: dimgray;
}

.forwardButtonOne{
    grid-column-start: 2;
    grid-row-start: 1;
    height: 30px;
    align-self: end;
    margin-bottom: 33px;
    font-weight: bold;
}

.forwardButtonTwo{
    grid-column-start: 2;
    grid-row-start: 1;
    height: 30px;
    align-self: end;
    font-weight: bold;
    margin-bottom: 2px;
}

.backButtonOne {
   grid-column-start: 2;
   grid-row-start: 2;
   height: 30px;
   align-self: start;
   font-weight: bold;
   margin-top: 2px;
}

.backButtonTwo {
    grid-column-start: 2;
    grid-row-start: 2;
    height: 30px;
    align-self: start;
    font-weight: bold;
    margin-top: 33px;
}

.availablePAs{
    grid-column-start: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    text-align: right;
    color: dimgray;
    font-size: 14px;
    
}