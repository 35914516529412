body {
  margin: 1rem;
  
  font-family: 'Arial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
  font-family: Arial;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.treeControlContainer {
    background: white;
    border: none;
    border-radius: 0.5%;
    padding: 1%;    
}

.treeContainer{
    height: 70vh;
    width: 480px;
    border: none;
}

.ReactVirtualized__Grid {
    outline: none;
}

 .ReactVirtualized__Grid__innerScrollContainer {
    padding-bottom: 70vh;
} 

.fa{
    margin-right: 10px;
    color: rgb(0,168,168);    
}

.node{
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    width: 430px;
    font-size: 16px;    
    padding: 0px !important;
}

.image{
    height: 15px;
    width: 15px;
}

.nodeLabel {
    margin-left: 10px;
    font-weight: normal;
    color: dimgray;
}

.select {
    width: 35px;
    -webkit-align-content: center;
            align-content: center;
}

a:visited {
    color: #0000EE;
}
.conflictContainer{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
            align-items: baseline;
}

.defaultConflict{
    margin-left: 5px;
    display: inline-block;
    width: 100%;
}
/* default modal styles */
.modal{ pointer-events: none; }
.modal-dialog{ pointer-events: none; }
.modal-content{ pointer-events: auto; } 

/* create/edit appraisal modal */
.appraisalModalStyle { width:800px; max-width: none; }

/* reports */
.reportModalStyle { width:900px; max-width: none;}
.findingsReportModalStyle { width:1100px; max-width: none;}
.toggle-button-items{   
    display: -webkit-flex;   
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.heading {
    margin-right: 10px;
}
.tab-content {   
    padding: 10px;
}

.nav-tabs {
    margin-bottom: 0;
}

.up-down-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.up-down-button {
    max-width: 40px;
    margin-right: 2px;
}

.add-delete-button {
    float: right;
}

.toolbar-button {
    margin-left: 5px;
}
.buttons {
    float: right;
    margin-left: 5px;
}
.findings-panel-group {
    border: 1px solid black;
    border-radius: 3px; 
    padding: 10px;
}
.container-fluid {
    padding-left: 0px;
}

.loading {
    display: block;
    margin: auto;
}
.bottomLabel{
    text-align:left;
    font-weight:bold;
    float:left;
}
.projLabelName {
    background-color: #FFF;
    border: #FFF;
    height: 14px;
    width: 90%;
    text-align: left;
    color: #000000;
    font-size: inherit;
    border-radius: 0;
}

.delProjButton {
    background-color: #FFF;
    height: 25px;
    width: 10%;
    box-sizing: border-box;
    border: #FFF;
    border-radius: 0;
}

.delProjButton:hover{
    background: #e1e1e1;
    border-top: 1px solid #d0d0d0;
}

.projectItems{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
            align-items: baseline;
}

.list-group-item {
    padding: 0px;
}

.checkBoxes {
    float: right;
}

.addTextBox {
    display: inline-block;
    width: 85%;
}

.addButton {
    display: inline-block;
    width: 10%;
    margin-left: 15px;
    margin-bottom: 5px;
    height: 34px;    
}

.projectsListGroup{
    overflow-y: scroll;
    font-size: 17px;
    max-height: 250px;
    max-width: 87%;
    color: dimgray;
}

.listControls{
    display: grid;
    grid-template-columns: 1fr 80px 1fr;
    grid-template-rows: 200px 200px;
    grid-column-gap: 5px;
}

.controlLabelOne{
    display: inline-block;
    color: dimgray;
}

.controlLabelTwo{
    float: right;
    color: dimgray;
}

.projectPAListGroup{
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 3;
    overflow-y: scroll;
    max-width: 100%;
    font-size: 14px;
    color: dimgray;
}

.forwardButtonOne{
    grid-column-start: 2;
    grid-row-start: 1;
    height: 30px;
    align-self: end;
    margin-bottom: 33px;
    font-weight: bold;
}

.forwardButtonTwo{
    grid-column-start: 2;
    grid-row-start: 1;
    height: 30px;
    align-self: end;
    font-weight: bold;
    margin-bottom: 2px;
}

.backButtonOne {
   grid-column-start: 2;
   grid-row-start: 2;
   height: 30px;
   align-self: start;
   font-weight: bold;
   margin-top: 2px;
}

.backButtonTwo {
    grid-column-start: 2;
    grid-row-start: 2;
    height: 30px;
    align-self: start;
    font-weight: bold;
    margin-top: 33px;
}

.availablePAs{
    grid-column-start: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    text-align: right;
    color: dimgray;
    font-size: 14px;
    
}
.LoaderButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
  }
  @-webkit-keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
  }
  @keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
  }
.buttons {
    float: right;
}
.backButton {
    float: left;
}
.NotFound {
    padding-top: 100px;
    text-align: center;
  }
.unconfirmed {
    text-align: center;
}
